import { createApp, Directive, DirectiveBinding, VNode } from 'vue'
import App from './App.vue'
import router from './router';

import { IonicVue } from '@ionic/vue';

import { store } from './store';
import i18n from './i18n';

import PrimeVue from 'primevue/config';
import 'primevue/resources/themes/md-dark-indigo/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';

import { es } from 'date-fns/locale'
import { setDefaultOptions } from 'date-fns'
setDefaultOptions({
  locale: es,
  'weekStartsOn': 1,
})

import VueSocialSharing from 'vue-social-sharing';

// Animations
import 'animate.css';
export const appear: Directive = {
  beforeMount(element: HTMLElement) {
    element.style.visibility = 'hidden';
  },
  updated(element: HTMLElement, binding: DirectiveBinding<boolean>, node: VNode) {
    if (!binding.value === !binding.oldValue || null === node.transition) {
      return;
    }

    if (!binding.value) {
      node.transition.leave(element, () => {
        element.style.visibility = 'hidden';
      });
      return;
    }

    node.transition.beforeEnter(element);
    element.style.visibility = '';
    node.transition.enter(element);
  }
};


/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';
import '../public/assets/css/custom.scss';
import '../public/assets/css/unifiedUI.scss';



const app = createApp(App)
  .use(i18n)
  .use(IonicVue)
  .use(router)
  .use(store)
  .use(VueSocialSharing)
  .use(PrimeVue);

  // SENTRY
import * as Sentry from "@sentry/vue";
import * as SentryBrowser from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";

Sentry.init({
  app,
  dsn: "https://34a7c87315974d70910f942f3c006462@o1221914.ingest.sentry.io/6737950",
  integrations: [
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracePropagationTargets: ["localhost", "app.docemasunorestaurante.com", /^\//],
    }),


    new SentryBrowser.Replay({
      // Additional SDK configuration goes in here, for example:
      maskAllText: false,
      blockAllMedia: true,
    }),
  ],

  tracesSampleRate: 1.0,

  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,

  environment: 'production',
  release: 'docemasunorestaurante-com_app@5.5.4',
  dist: '534',
});
  
router.isReady().then(() => {
  app.directive('appear', appear)
  .mount('#app');
});

